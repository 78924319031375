import { PropsWithChildren } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  VStack,
  colors,
  HStack,
} from "@biblioteksentralen/js-utils";
import { Gallery, SanityKeyed } from "@libry-content/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";

import { safelyGetImageDimensions } from "../../utils/safelyGetImageDimensions";
import { usePreviewContext } from "../preview/previewContext";
import Masonry from "../masonry/Masonry";
import { GalleryImage as GalleryImageType } from "../SanityImage";
import useFocusedImage, { FOCUSED_IMAGE_SLIDE_SPACING_REM } from "./useFocusedImage";
import GalleryNavigationButton from "./GalleryNavigationButton";
import GalleryImage from "./GalleryImage";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useBlockContentContext } from "./BlockContentContext";
import { isBright } from "../../utils/colors";
import onDesktop from "../../utils/onDesktop";

const FOCUSED_IMAGE_WIDTH = "65vmin";

const getImageHeight = (image: GalleryImageType): string =>
  `calc(${FOCUSED_IMAGE_WIDTH} / ${safelyGetImageDimensions(image).aspectRatio})`;

type Props = PropsWithChildren<PortableTextTypeComponentProps<SanityKeyed<Gallery>>>;

export function TextGallerySerializer({ value, ...rest }: Props) {
  const { ts } = useTranslation();
  const previewContext = usePreviewContext();
  const { galleryBackgroundColors } = useBlockContentContext();
  const backgroundColor = galleryBackgroundColors?.[value?._key] ?? colors.grey05;

  const {
    focusImage,
    unFocusImage,
    focusedImage,
    previousFocusedImage,
    nextFocusedImage,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    navigateFocusedImage,
    focusedImageStyle,
  } = useFocusedImage(value?.images);

  if (!value?.images?.find?.(({ asset }) => asset)) return null;

  return (
    <>
      <VStack
        background={backgroundColor}
        color={isBright(backgroundColor) ? colors.black : colors.white}
        borderRadius={{ base: "0", md: "0.75rem" }}
        padding={{ base: "2rem 1rem", sm: "2rem 3rem" }}
        marginBottom="2rem"
        maxWidth={{ base: "100%", md: "80%" }}
        alignItems={{ base: "center", md: "flex-start" }}
      >
        {!!ts(value.title) && (
          <Heading as="h2" size="md" marginBottom={{ base: "0.5rem", sm: "1rem" }}>
            {ts(value.title)}
          </Heading>
        )}
        <Masonry nColumns={{ base: 2, sm: 3 }} gap="1rem">
          {value.images
            .filter(({ asset }) => asset)
            .map((image) => (
              <GalleryImage
                key={image._key}
                image={image}
                onClick={() => focusImage(image)}
                cursor="pointer"
                _hover={onDesktop({ filter: "brightness(80%)" })}
                transition={"0.3s ease filter"}
              />
            ))}
        </Masonry>
      </VStack>
      {/* Don't trap focus in preview because this prevents typing in editor */}
      {!!focusedImage && (
        <Modal isOpen={!!focusedImage} onClose={unFocusImage} trapFocus={!previewContext.isOn} isCentered>
          <ModalOverlay background="rgba(0, 0, 0, 0.7)" />
          <ModalContent
            padding="0"
            margin="0"
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
            onTouchEnd={onTouchEnd}
            maxWidth={FOCUSED_IMAGE_WIDTH}
            background="transparent"
          >
            <ModalBody padding="0" width="fit-content">
              <GalleryNavigationButton onClick={() => navigateFocusedImage(-1)} left="-3rem">
                <FiChevronLeft />
              </GalleryNavigationButton>
              <HStack
                justifyContent="center"
                width={FOCUSED_IMAGE_WIDTH}
                overflow="hidden"
                gap={`${FOCUSED_IMAGE_SLIDE_SPACING_REM}rem`}
              >
                {previousFocusedImage && (
                  <GalleryImage
                    image={previousFocusedImage}
                    type="highlight"
                    {...focusedImageStyle}
                    width={FOCUSED_IMAGE_WIDTH}
                    height={getImageHeight(previousFocusedImage)}
                  />
                )}
                <GalleryImage
                  image={focusedImage}
                  onClick={unFocusImage}
                  type="highlight"
                  {...focusedImageStyle}
                  width={FOCUSED_IMAGE_WIDTH}
                  height={getImageHeight(focusedImage)}
                />
                {nextFocusedImage && (
                  <GalleryImage
                    image={nextFocusedImage}
                    type="highlight"
                    {...focusedImageStyle}
                    width={FOCUSED_IMAGE_WIDTH}
                    height={getImageHeight(nextFocusedImage)}
                  />
                )}
              </HStack>
              <GalleryNavigationButton onClick={() => navigateFocusedImage(1)} right="-3rem">
                <FiChevronRight />
              </GalleryNavigationButton>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
