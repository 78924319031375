import { BsPencil, colors, Link, LinkProps } from "@biblioteksentralen/js-utils";
import {
  CreateNewableType,
  EditableDocument,
  EditableType,
  getCreateDocumentUrl,
  getEditDocumentUrl
} from "@libry-content/common";
import { ReactNode, useState } from "react";
import { v4 } from "uuid";
import { logAdminClick } from "../../utils/analytics/Plausible";
import { usePreviewContext } from "../preview/previewContext";
import { useEditInSanityContext } from "./EditInSanityContext";

const StyledLink = (props: LinkProps) => (
  <Link
    color="black"
    paddingX=".2rem"
    width="max-content"
    backgroundColor={colors.alertYellow}
    display="inline-flex"
    alignItems="center"
    gap="0.5rem"
    {...props}
  />
);

function ShowIfEditMode(props: { children: ReactNode }) {
  const show = useEditInSanityContext().editMode;
  const preview = usePreviewContext();

  if (!show || preview.isOn) {
    return null;
  }

  return <>{props.children}</>;
}

const defaultEditLabels: Record<EditableType, string> = {
  site: "Oppdater nettsted",
  library: "Oppdater bibliotek",
  event: "Oppdater arrangement",
  service: "Oppdater tjeneste",
  list: "Oppdater liste",
  staticPage: "Oppdater side",
  alert: "Oppdater varsel",
  privacyPolicy: "Oppdater personvern",
  footerMenuCollection: "Oppdater bunnmenyer",
  sommerles: "Oppdater",
  adventCalendar: "Oppdater julekalender",
  recommendation: "Oppdater anbefaling",
  digitalLibraryServiceBibliofilApp: "Oppdater tjeneste",
  digitalLibraryServiceBookbites: "Oppdater tjeneste",
  digitalLibraryServiceVerdensbiblioteket: "Oppdater tjeneste",
  digitalLibraryServiceDigitaltNasjonaltLanekort: "Oppdater tjeneste",
  digitalLibraryServiceBibliotekSok: "Oppdater tjeneste",
  digitalLibraryServiceCustom: "Oppdater tjeneste",
};

interface EditProps extends LinkProps {
  doc: EditableDocument;
  label?: string;
}

export function Edit(props: EditProps) {
  const editUrl = getEditDocumentUrl(props.doc);
  const { doc, label, ...styleProps } = props;

  if (!editUrl) return null;

  const text = label ?? defaultEditLabels[doc._type] ?? "Endre";
  return (
    <ShowIfEditMode>
      <StyledLink onClick={() => logAdminClick(text)} href={editUrl} {...styleProps}>
        <BsPencil /> {text}
      </StyledLink>
    </ShowIfEditMode>
  );
}

const createNewLabels: Record<CreateNewableType, string> = {
  library: "Legg til nytt bibliotek",
  event: "Legg til nytt arrangement",
  service: "Legg til ny tjeneste",
  list: "Legg til ny liste",
  staticPage: "Legg til en fast side",
  recommendation: "Legg til ny anbefaling",
  digitalLibraryServiceBibliofilApp: "Legg til ny tjeneste",
  digitalLibraryServiceBookbites: "Legg til ny tjeneste",
  digitalLibraryServiceVerdensbiblioteket: "Legg til ny tjeneste",
  digitalLibraryServiceDigitaltNasjonaltLanekort: "Legg til ny tjeneste",
  digitalLibraryServiceBibliotekSok: "Legg til ny tjeneste",
  digitalLibraryServiceCustom: "Legg til ny tjeneste",
};

interface CreateNewProps {
  type: CreateNewableType;
}

const useCreateNewUrl = (type: CreateNewableType, newDocId: string) => {
  switch (type) {
    case "digitalLibraryServiceBibliofilApp":
    case "digitalLibraryServiceBookbites":
    case "digitalLibraryServiceVerdensbiblioteket":
    case "digitalLibraryServiceDigitaltNasjonaltLanekort":
    case "digitalLibraryServiceBibliotekSok":
    case "digitalLibraryServiceCustom": {
      return "/cms/desk/digitalLibrary";
    }
    default: {
      return getCreateDocumentUrl(type, newDocId);
    }
  }
};

export function CreateNew({ type, ...chakraProps }: CreateNewProps & LinkProps) {
  const [newDocId, setNewDocId] = useState(v4());
  const createNewUrl = useCreateNewUrl(type, newDocId);

  if (!createNewUrl) return null;
  const label = createNewLabels[type] ?? `Opprett ${type}`;
  return (
    <ShowIfEditMode>
      <StyledLink
        onClick={() => {
          logAdminClick(label);
          setNewDocId(v4()); // Update id when creating
        }}
        href={createNewUrl}
        {...chakraProps}
      >
        <BsPencil /> {label}
      </StyledLink>
    </ShowIfEditMode>
  );
}
